 <!-- tel-input.vue -->
  <template>
    <input 
        class="form-control" 
        type="text" 
        :id=schema.model
        :v-model=schema.model
        :ref=schema.model
        v-on:keyup="checkAndMoveToNext" 
        :disabled="disabled"
        :maxlength="schema.max"
        :placeholder="schema.placeholder"
        :readonly="schema.readonly" >
</template>
    
  <script>
import { abstractField } from "vue-form-generator";

export default {
    name: "TelephoneInput",
    mixins: [abstractField],
    data () {
    return {
        phone1:'',
        phone2:'',
        phone3:'',
    }
  },
    methods: {
        checkAndMoveToNext(){
            if(document.getElementById("phone1").value.length == 3) {
                this.$props.model.phone1 = document.getElementById("phone1").value;
                document.getElementById("phone2").focus();
            }
        },
    },
    created (){
        const props = this.$props.model;
        this.phone1 = this.$props.model.phone1
        

    },
};
</script>
