/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
const axios = require("axios");
window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue").default
);
Vue.component(
    "location-form",
    require("./components/LocationForm.vue").default
);
Vue.component(
    "circular-progress",
    require("./components/CircularProgress.vue").default
);
//global registration
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import VueTelInput from "vue-tel-input";
import TelInputField from "./components/tel-input-field.vue";
import TelInputField1 from "./components/tel-input-field-1.vue";

const allphones = require("./components/phones.json");

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.use(VueTelInput);
Vue.use(VueFormGenerator);

Vue.component("field-tel-input", TelInputField);
Vue.component("field-tel-input-1", TelInputField1);

VueFormGenerator.validators.customemail = function(value, field, model) {
    var re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    var out = re.test(value);

    if (!out) {
        return ["Email is not valid"];
    }
    return [];
};

VueFormGenerator.validators.dropdown = function(value, field, model) {
    if (value == "DD" || value == "MM") {
        return ["Date of birth is not valid"];
    }
    return [];
};

//TO DEBUG CHANGE HERE
// const DEBUG_MODE = true;
 let DEBUG_MODE = false;

const BACKSPACE = 8; //keycode
const MEDICARE_AGE = 65;
const MEDICARE_FORM_LENGTH = 5;
const HEALTHCARE_FORM_LENGTH = 7;

//to get to medicare form quickly
//with test values change here

//medicare flow
const FORM_COMPLETED_START = DEBUG_MODE ? HEALTHCARE_FORM_LENGTH : 1;

//healthcare flow
// const FORM_COMPLETED_START = DEBUG_MODE ? HEALTHCARE_FORM_LENGTH : 1;

const ONE_FORM_STEP = 1;
const TWO_FORM_STEP = 2;
const CURRENT_YEAR = new Date().getFullYear();

let urlParams = new URLSearchParams(window.location.search);
let date_of_birth = urlParams.get("dob")
    ? urlParams.get("dob")
    : urlParams.get("contact.dob_month/contact.dob_day/contact.day_year");
var res = date_of_birth ? date_of_birth.split("/") : "";
let dobmonth = res[0] ? res[0] : "";
let dobday = res[1] ? res[1] : "";
let dobyear = res[2] ? res[2] : "";
let address =
    urlParams.get("address") || urlParams.get("contact.street")
        ? urlParams.get("address") || urlParams.get("contact.street")
        : "";
let gender = urlParams.get("gender")
    ? urlParams.get("gender")
    : urlParams.get("$gender$");
if (gender) {
    gender = gender.toLowerCase();
    gender = gender == "female" ? "F" : "M";
}
let firstname = urlParams.get("first_name")
    ? urlParams.get("first_name")
    : urlParams.get("contact.first_name");
let lastname = urlParams.get("last_name")
    ? urlParams.get("last_name")
    : urlParams.get("contact.last_name");
let email = urlParams.get("email");
let phone =
    urlParams.get("primary_phone") || urlParams.get("contact.phone_cell")
        ? urlParams.get("primary_phone") || urlParams.get("contact.phone_cell")
        : "";
//function must return (304) 849-2404
if (phone) {
    phone = formatPhoneNumber(phone);
}

var phone1 = phone ? phone.match(/\(([^)]+)\)/)[1] : "";
let phone2 = phone ? phone.split(/ (.+)-/)[1] : "";
let phone3 = phone ? phone.split(/-(.+)/)[1] : "";
let household = urlParams.get("household_size");
let income = urlParams.get("household_income");
let age = urlParams.get("age");
// let zip = urlParams.get('{zip}') ? urlParams.get('{zip}') : urlParams.get('$zip');
let segm = urlParams.get("$segm$");
if (
    segm == "Yes" ||
    segm == "yes" ||
    segm == "y" ||
    segm == "Y" ||
    segm == "No" ||
    segm == "no" ||
    segm == "n" ||
    segm == "N"
) {
    household =
        segm == "Yes" || segm == "yes" || segm == "y" || segm == "Y"
            ? "3"
            : "1";
}
let showme = urlParams.get("showme") ? urlParams.get("showme") : "";

var getGeoData = function(callback) {
    $.ajax({
        url: "https://ipinfo.io/json?token=3c2d1fd8d3a4a9",
        jsonpCallback: "callback",
        dataType: "jsonp",
        success: callback
    });
};
getGeoData(function(location) {    
      localStorage.setItem("user_ipv4", location.ip);
});

new Vue({
    el: "#app",
    data: {
        number: null,
        model: {
            dob_month: dobmonth || (DEBUG_MODE ? "01" : ""),
            dob_day: dobday || (DEBUG_MODE ? "01" : ""),
            dob_year: dobyear || 1956,
            first_name: firstname || (DEBUG_MODE ? "test" : ""),
            last_name: lastname || (DEBUG_MODE ? "test" : ""),
            city: "Corona",
            citygoogle: "",
            state: "CA",
            zip: DEBUG_MODE ? "98111" : "",
            wrongaddress: true,
            address: "",
            household: household || 2,
            income: income || "60000",
            gender: gender || "M",
            currentlyEnrolled: "",
            email: email || (DEBUG_MODE ? "test@test.com" : ""),
            addresserror: false,
            errorday: false,
            errormonth: false,
            errorcity: false,
            errorzip: false,
            errorphone: false,
            errorfirst: false,
            errorlast: false,
            erroremail: false,
            errorphone1: false,
            errorphone2: false,
            errorphone3: false,
            phone1: phone1 || (DEBUG_MODE ? "208" : ""),
            phone2: phone2 || (DEBUG_MODE ? "840" : ""),
            phone3: phone3 || (DEBUG_MODE ? "2345" : ""),
            erroraddress: false,
            erroraddressvalid: false,
            ip_address:
                localStorage.getItem("user_ipv4") ||
                "no ip address in local storage hcm",
            ip_address_error:
                localStorage.getItem("ip_address_error") ||
                "no ip_error in local storage hcm",
            tobacco: "",
            self_employed: "",
            feet: "",
            weight: "",
            pregnant: "",
            medical: "",
            universal_leadid: "",
            trusted_form_token: "",
            trusted_form_url: "",
            source: "",
            affiliate: "a",
            creative: "",
            redirect_db: "https://healthcaremarketplace.com",
            requestid: "",
            oid: "",
            is_robly: "",
            offer: "",
            tfn: "",
            arcamax_signup: "",
            boberdoo_src: "",
            source1: "",
            source2: "",
            source3: "",
            source4: "",
            source5: "",
            showme: showme || ""
        },
        ten: false,
        formValues: {
            years: [
                "YYYY",
                "1921",
                "1922",
                "1923",
                "1924",
                "1925",
                "1926",
                "1927",
                "1928",
                "1929",
                "1930",
                "1931",
                "1932",
                "1933",
                "1934",
                "1935",
                "1936",
                "1937",
                "1938",
                "1939",
                "1940",
                "1941",
                "1942",
                "1943",
                "1944",
                "1945",
                "1946",
                "1947",
                "1948",
                "1949",
                "1950",
                "1951",
                "1952",
                "1953",
                "1954",
                "1955",
                "1956",
                "1957",
                "1958",
                "1959",
                "1960",
                "1961",
                "1962",
                "1963",
                "1964",
                "1965",
                "1966",
                "1967",
                "1968",
                "1969",
                "1970",
                "1971",
                "1972",
                "1973",
                "1974",
                "1975",
                "1976",
                "1977",
                "1978",
                "1979",
                "1980",
                "1981",
                "1982",
                "1983",
                "1984",
                "1985",
                "1986",
                "1987",
                "1988",
                "1989",
                "1990",
                "1991",
                "1992",
                "1993",
                "1994",
                "1995",
                "1996",
                "1997",
                "1998",
                "1999",
                "2000",
                "2001"
            ],
            months: [
                "MM",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12"
            ],
            days: [
                "DD",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31"
            ],
            incomes: ["20000", "40000", "60000", "80000"],
            households: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
        },
        formOptions: {
            validationErrorClass: "has-error",
            validationSuccessClass: "has-success",
            validateAfterChanged: true
        },
        dobTabSchema: {
            fields: [
                {
                    type: "select",
                    label: "",
                    model: "dob_month",
                    required: true,
                    //                    validator: VueFormGenerator.validators.dropdown,
                    validator: VueFormGenerator.validators.dropdown,
                    values: [
                        "MM",
                        "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        "08",
                        "09",
                        "10",
                        "11",
                        "12"
                    ],
                    styleClasses: "col-xs-6 dob dob_month",
                    selectOptions: {
                        noneSelectedText: " "
                    }
                },
                {
                    type: "select",
                    label: "",
                    model: "dob_day",
                    required: true,
                    validator: VueFormGenerator.validators.dropdown,
                    values: [
                        "DD",
                        "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        "08",
                        "09",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "28",
                        "29",
                        "30",
                        "31"
                    ],
                    styleClasses: "col-xs-6 dob dob_day",
                    selectOptions: {
                        noneSelectedText: " "
                    }
                },
                {
                    type: "select",
                    label: "",
                    model: "dob_year",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    values: [
                        "1921",
                        "1922",
                        "1923",
                        "1924",
                        "1925",
                        "1926",
                        "1927",
                        "1928",
                        "1929",
                        "1930",
                        "1931",
                        "1932",
                        "1933",
                        "1934",
                        "1935",
                        "1936",
                        "1937",
                        "1938",
                        "1939",
                        "1940",
                        "1941",
                        "1942",
                        "1943",
                        "1944",
                        "1945",
                        "1946",
                        "1947",
                        "1948",
                        "1949",
                        "1950",
                        "1951",
                        "1952",
                        "1953",
                        "1954",
                        "1955",
                        "1956",
                        "1957",
                        "1958",
                        "1959",
                        "1960",
                        "1961",
                        "1962",
                        "1963",
                        "1964",
                        "1965",
                        "1966",
                        "1967",
                        "1968",
                        "1969",
                        "1970",
                        "1971",
                        "1972",
                        "1973",
                        "1974",
                        "1975",
                        "1976",
                        "1977",
                        "1978",
                        "1979",
                        "1980",
                        "1981",
                        "1982",
                        "1983",
                        "1984",
                        "1985",
                        "1986",
                        "1987",
                        "1988",
                        "1989",
                        "1990",
                        "1991",
                        "1992",
                        "1993",
                        "1994",
                        "1995",
                        "1996",
                        "1997",
                        "1998",
                        "1999",
                        "2000",
                        "2001"
                    ],
                    styleClasses: "col-xs-6 dob dob_year",
                    selectOptions: {
                        noneSelectedText: "YYYY"
                    }
                }
            ]
        },
        genderTabSchema: {
            fields: [
                {
                    type: "radios",
                    label: "What is your Gender?",
                    model: "gender",
                    //                values: ["Male", "Female"],
                    values: [
                        { name: "Male", value: "M" },
                        { name: "Female", value: "F" }
                    ],
                    required: false,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "gender_radio flex"
                }
            ]
        },
        currentlyEnrolledTabSchema: {
            fields: [
                {
                    type: "radios",
                    label:
                        "Are you currently enrolled in Medicare or Medicaid?",
                    model: "currentlyEnrolled",
                    //                values: ["Male", "Female"],
                    values: [
                        { name: "Yes ", value: "True" },
                        { name: "No", value: "False" },
                       // { name: "I don’t know", value: "Unknown" }
                    ],
                    required: false,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "gender_radio flex currently_enrolled"
                }
            ]
        },
        householdTabSchema: {
            fields: [
                {
                    type: "select",
                    required: false,
                    label: "What is your household size?",
                    model: "household",
                    values: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-xs-6 dob",
                    selectOptions: {
                        hideNoneSelectedText: true
                    }
                }
            ]
        },
        houseIncomeTabSchema: {
            fields: [
                {
                    type: "select",
                    required: false,
                    model: "income",
                    values: function() {
                        return [
                            { id: "20000", name: "$0 to $20,000" },
                            { id: "40000", name: "$20,001 to $40,000" },
                            { id: "60000", name: "$40,001 to $60,000" },
                            { id: "80000", name: "$60,001 to $80,000" },
                            { id: "90000", name: "$80,001+" }
                        ];
                    },
                    default: "60000",
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-xs-6 dob dob_day mb-0",
                    selectOptions: {
                        hideNoneSelectedText: true
                    }
                }
            ]
        },
        secondTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "First Name",
                    placeholder: "First",
                    model: "first_name",
                    min: 2,
                    required: true,
                    //                    validator: VueFormGenerator.validators.string,
                    validator: VueFormGenerator.validators.string.locale({
                        fieldIsRequired: "First Name is not valid"
                    }),
                    styleClasses: "firstname"
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "Last Name",
                    placeholder: "Last",
                    model: "last_name",
                    min: 2,
                    required: true,
                    validator: VueFormGenerator.validators.string.locale({
                        fieldIsRequired: "Last Name is not valid"
                    }),
                    styleClasses: "lastname"
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "Email",
                    model: "email",
                    required: true,
                    validator: VueFormGenerator.validators.customemail,
                    styleClasses: "emailaddress col-lg-12 col-md-12 "
                }
                //                {
                //                    type: "tel-input",
                //                    label: "Phone",
                //                    model: "phone1",
                //                    placeholder: "###",
                //                    max:3,
                //                    maxlength:3,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 mb-3',
                //                    phstyles: 'float-left mr-3 form-control '
                //                },
                //                {
                //                    type: "tel-input-1",
                //                    label: "",
                //                    model: "phone2",
                //                    placeholder: "###",
                //                    max:3,
                //                    maxlength:3,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 phone2',
                //                    phstyles: 'float-left mr-3 form-control '
                //                },
                //                {
                //                    type: "input",
                //                    inputType: "text",
                //                    label: "",
                //                    model: "phone3",
                //                    placeholder: "####",
                //                    max:4,
                //                    maxlength:4,
                //                    required: true,
                //                    validator: VueFormGenerator.validators.string.locale({
                //                        fieldIsRequired: "Phone Number is not valid",
                //                    }),
                //                    styleClasses: 'phonenumber col-md-12 phone2',
                //                    phstyles: 'float-left mr-3 form-control '
                //                }
            ]
        },
        thirdTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "Address",
                    model: "street",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-lg-6 col-xs-12 "
                },
                {
                    type: "input",
                    inputType: "text",
                    label: "City",
                    model: "city",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    styleClasses: "col-lg-3 col-xs-12"
                },
                {
                    type: "select",
                    label: "State",
                    model: "state",
                    required: true,
                    validator: VueFormGenerator.validators.string,
                    values: [
                        "AL",
                        "AK",
                        "AS",
                        "AZ",
                        "AR",
                        "CA",
                        "CO",
                        "CT",
                        "DE",
                        "DC",
                        "FM",
                        "FL",
                        "GA",
                        "GU",
                        "HI",
                        "ID",
                        "IL",
                        "IN",
                        "IA",
                        "KS",
                        "KY",
                        "LA",
                        "ME",
                        "MH",
                        "MD",
                        "MA",
                        "MI",
                        "MN",
                        "MS",
                        "MO",
                        "MT",
                        "NE",
                        "NV",
                        "NH",
                        "NJ",
                        "NM",
                        "NY",
                        "NC",
                        "ND",
                        "MP",
                        "OH",
                        "OK",
                        "OR",
                        "PW",
                        "PA",
                        "PR",
                        "RI",
                        "SC",
                        "SD",
                        "TN",
                        "TX",
                        "UT",
                        "VT",
                        "VI",
                        "VA",
                        "WA",
                        "WV",
                        "WI",
                        "WY"
                    ],
                    styleClasses: "col-lg-3 col-xs-12",
                    selectOptions: {
                        noneSelectedText: "Select a State"
                    }
                }
            ]
        },
        fourthTabSchema: {
            fields: [
                {
                    type: "input",
                    inputType: "text",
                    label: "Email Address",
                    model: "email",
                    required: true,
                    validator: VueFormGenerator.validators.email,
                    styleClasses: "emailaddress col-lg-12 col-md-12 "
                },
                {
                    type: "tel-input",
                    label: "Phone Number",
                    model: "phone",
                    placeholder: "(###) ### - ####",
                    styleClasses: "phonenumber col-md-12 ",
                    phstyles: "form-control col-md-6 mx-auto"
                }
            ]
        },
        completedSteps: FORM_COMPLETED_START, //*****change here to test a different part of form******
        notActive: true,
        totalSteps:
            window.location.pathname.indexOf("health") === -1
                ? MEDICARE_FORM_LENGTH
                : HEALTHCARE_FORM_LENGTH,
        medicare: window.location.pathname.includes("medicare")
            ? "medicare"
            : "",
        healthcare: window.location.href.includes("healthcare"),
        flow: window.location.pathname
            .split("/")
            .find(string => string === "medicare" || string === "healthcare"),
        floww: "",
        back: "Back",
        transitionName: "bounce",
        last: false,
        stepAmount: 1,
        medicareQuestionStep: 3
    },
    computed: {
        errorFirst: function() {
            return this.model.errorfirst;
        },
        errorLast: function() {
            return this.model.errorlast;
        },
        errorEmail: function() {
            return this.model.erroremail;
        },
        errorPhone1: function() {
            return this.model.errorphone1;
        },
        errorPhone2: function() {
            return this.model.errorphone2;
        },
        errorPhone3: function() {
            return this.model.errorphone3;
        },
        errorPhone: function() {
            return this.model.errorphone;
        }
    },
    watch: {
        first_name(val) {
            console.log("idemoo");
            this.model.first_name = val.replace(/\W/g, "");
        }
    },
    mounted() {
        //retreaver
        const campaignKey = '29d6517e3732b8b1190b602fe8590499';
        var a=document.createElement("script");
        a.type="text/javascript";a.async=!0;
        a.defer=!0;a.src=document.location.protocol+"//dist.routingapi.com/jsapi/v1/retreaver.min.js";
        const vm = this
        a.onload=a.onreadystatechange=function(){
            Retreaver.Base.Cookies.set('CallPixels-ou', '');
            Retreaver.configure({host:"api.routingapi.com",prefix:"https:"==document.location.protocol?"https":"http"});
            // console.log(vm.model.income)
            var rcampaign = new Retreaver.Campaign({campaign_key: campaignKey});
            rcampaign.auto_replace_numbers(function (number) {
                vm.number = number
                vm.getTags()
            });
        };
        (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(a)
    },
    created() {
        this.getGeoInfo(); //get city name from zip

        if (this.medicare == "medicare") {
            // this.model.dobyear = "1956";
            if (
                !urlParams.get("gender") ||
                (urlParams.get("gender").toLowerCase() != "male" &&
                    urlParams.get("gender").toLowerCase() != "female")
            ) {
                this.model.gender = "";
            }
            if (this.validateDobOncreate()) {
                if (!this.validateGenderOncreate()) this.model.gender = "M";
            }
        } else {
            if (this.validateDobOncreate()) {
                if (!this.validateGenderOncreate()) {
                    this.model.gender = "M";
                }
                if (this.validateHouseholdOncreate()) {
                    // alert('true');
                } else {
                    this.model.household = 2;
                }
                if (this.validateIncomeOnCreate()) {
                    // this.completedSteps = 5;
                } else {
                    this.model.income = 60000;
                }
            }
        }

        changeButtonId(this.completedSteps);

        this.model.zip = this.getQueryVariable("zipcode");

        let uri = window.location.href.split("?");
        if (uri.length == 2) {
            let vars = uri[1].split("&");
            let getVars = {};
            let tmp = "";
            let model = this.model;
            vars.forEach(function(v) {
                tmp = v.split("=");
                if (tmp.length == 2) {
                    getVars[tmp[0]] = tmp[1];
                    if (tmp[0] == "a") {
                        model.affiliate = tmp[1];
                    }
                    if (tmp[0] == "c") {
                        model.creative = tmp[1];
                    }
                    if (tmp[0] == "s1") {
                        model.source1 = tmp[1];
                    }
                    if (tmp[0] == "s2") {
                        model.source2 = tmp[1];
                    }
                    if (tmp[0] == "s3") {
                        model.source3 = tmp[1];
                    }
                    if (tmp[0] == "s4") {
                        model.source4 = tmp[1];
                    }
                    if (tmp[0] == "s5") {
                        model.source5 = tmp[1];
                    }
                    if (tmp[0] == "r") {
                        model.requestid = tmp[1];
                    }
                    if (tmp[0] == "oid") {
                        model.oid = tmp[1];
                    }
                }
            });

            this.model = model;
           
            console.log(getVars);
            // do
        }

        //this.getGeoInfo();
    },
    methods: {
        getTags(){
          
            const universal_leadid_el = document.getElementById("leadid_token");
            const universal_leadid_v = universal_leadid_el
                ? universal_leadid_el.value
                : "";
            const trusted_form_url_el = document.getElementById(
                "xxTrustedFormCertUrl_0"
                );
            const trusted_form_url_v = trusted_form_url_el
                    ? trusted_form_url_el.value
                    : "";

            var bday = this.model.dob_year + "-" + this.model.dob_month + "-" + this.model.dob_day;
            const age = this.getAge(bday).years;
            
            console.log(age, this.model.agegroup, this.model.income);
            const dob = this.model.dob_day + '/' + this.model.dob_month + '/' + this.model.dob_year;            
            

            this.number.replace_tags({
                state: this.model.state,
                first_name: this.model.first_name,
                last_name: this.model.last_name,
                phone: this.model.phone,
                address: this.model.address,
                city: this.model.city,
                email: this.model.email,
                zip: this.model.zip,
                gender: this.model.gender,
                household: this.model.household,
                income: this.model.income,
                qualified_event: this.model.thingsHappened,
                agegroup2: this.model.agegroup,
                age: age,
                dobt: dob,
                currentlyhas_medicare: this.model.currentlyEnrolled,
                jornaya_id: universal_leadid_v,
                trusted_form: trusted_form_url_v,
            });
        },
        isHealthCareFlow: function() {
            return this.medicare === "";
        },
        isMedicareFlow: function() {
            return this.medicare === "medicare";
        },
        isNewStep: function() {
            return this.completedSteps === 3;
        },
        getNewStep: function() {
            return 3;
        },
        selectionCheckboxClicked: function(ev) {
            console.log("sdf");
            var element = document.getElementById(".errors.help-block");
            element.classList.add("hide");

            var element = document.getElementById("zipcode");
            element.classList.remove("red-borde");
        },
        nameKeydown(e) {
            if (/^\W$/.test(e.key) || !isNaN(e.key)) {
                e.preventDefault();
            }
        },
        NumberLimit3(e) {
            console.log(e.key);
        },
        NumberLimit4(e) {
            console.log(e.key);
        },
        clearDay: function() {
            this.model.errorday = false;
        },
        clearMonth: function() {
            this.model.errormonth = false;
        },
        clearFirst: function() {
            this.model.errorfirst = false;
        },
        clearLast: function() {
            this.model.errorlast = false;
        },
        clearPhone1: function() {
            this.model.errorphone1 = false;
            this.model.errorphone = false;
        },
        clearPhone2: function() {
            this.model.errorphone2 = false;
            this.model.errorphone = false;
        },
        clearPhone3: function() {
            this.model.errorphone3 = false;
            this.model.errorphone = false;
        },
        clearEmail: function() {
            this.model.erroremail = false;
        },
        errorAddAndCityForm: function() {
            if (
                this.model.city != "" &&
                this.model.zip != "" &&
                this.model.address != ""
            ) {
                this.model.errorcity = false;
                this.model.errorzip = false;

                // var re = /^(([a-zA-Z]){2})/;
                this.model.erroraddress = false;
                var patt = new RegExp("(([a-zA-Z]){2})");
                var valid = patt.test(this.model.address);
                if (valid) {
                    this.model.erroraddressvalid = false;
                    return true;
                }
                this.model.erroraddressvalid = true;
                return false;
            }

            if (this.model.address == "") {
                this.model.erroraddress = true;
            } else {
                this.model.erroraddress = false;
            }

            if (this.model.city == "") {
                this.model.errorcity = true;
            } else {
                this.model.errorcity = false;
            }

            if (this.model.zip == "") {
                this.model.errorzip = true;
            } else {
                this.model.errorzip = false;
            }

            return false;
        },
        errorAddForm: function() {},
        checkValidPhone(number) {
            if (allphones.includes(number)) {
                this.model.errorphone = false;
                return true;
            }

            this.model.errorphone = true;
            return false;
        },
        getRandomInt: function(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        skipStep: function(event) {
            this.completedSteps++;
            event.target.id = "step" + this.completedSteps;
        },
        nextStep: function(event) {
            
            window.scrollTo(0, 0);
           
            this.transitionName = "bounce";
             let agegroup = this.model.agegroup;
            //first step check date and get city name from zip
            if (this.completedSteps === FORM_COMPLETED_START) {
                if (!this.validateDate()) return;
                //get dob
                var bday =
                    this.model.dob_year +
                    "-" +
                    this.model.dob_month +
                    "-" +
                    this.model.dob_day;

                //get age from dob
                var age = this.getAge(bday);
                localStorage.setItem("age", age.years);
                //if user did not input date, get it from years
                //TODO: check if user picks YYYY option
                if (!age.years) {
                    age.years = CURRENT_YEAR - this.model.dob_year;
                }

                var newUrlIS;
                let str = window.location.href;
                let afterQuestionMark = str.split("?")[1];
                //console.log(afterQuestionMark);

                //take out type param if there in case user
                //changes dob after pressing the back button
                afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=ace",
                    ""
                );
                afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=healthcare",
                    ""
                );
                afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=dual",
                    ""
                );

                //check if medicare of healthcare
               
                if (age.years < MEDICARE_AGE) {
                    this.flow = "healthcare";
                    this.medicare = "";
                    if (window.location.search.indexOf('biden=yes') > -1) {
                        agegroup = 'aca';
                        this.totalSteps = HEALTHCARE_FORM_LENGTH;
                        newUrlIS =
                        window.location.origin +
                        "/healthcare?" +
                        afterQuestionMark +
                        "&agegroup=aca";
                    } else {                   
                        agegroup = 'healthcare';
                        this.totalSteps = HEALTHCARE_FORM_LENGTH;
                        newUrlIS =
                        window.location.origin +
                        "/healthcare?" +
                        afterQuestionMark +
                        "&agegroup=healthcare";
                    }
                } else if (age.years >= MEDICARE_AGE) {
                    this.flow = "medicare";
                    this.medicare = "medicare";
                     agegroup = 'dual'; 
                    this.totalSteps = MEDICARE_FORM_LENGTH;
                    newUrlIS =
                        window.location.origin +
                        "/medicare?" +
                        afterQuestionMark +
                        "&agegroup=dual";
                }
                
                this.model.type = this.flow;
             
                //puts new url in search and navigates to page
                history.pushState({}, null, newUrlIS);
            }

            //check if it is the step before the medicare question
            if (this.completedSteps === this.medicareQuestionStep - 1) {
                //get dob
                var bday =
                    this.model.dob_year +
                    "-" +
                    this.model.dob_month +
                    "-" +
                    this.model.dob_day;

                //get age from dob
                var age = this.getAge(bday);

                //if user did not input date, get it from years
                //TODO: check if user picks YYYY option
                if (!age.years) {
                    age.years = CURRENT_YEAR - this.model.dob_year;
                }

                //if under 65 years skip medicare question step
                if (this.flow === "medicare") {
                    this.stepAmount =
                        age.years >= 65 ? ONE_FORM_STEP : TWO_FORM_STEP;
                }

                //go up necessary steps
                this.completedSteps += this.stepAmount;

                /*
                WARNING:
                these lines are what's making the id's different
                than what they are in the codebase
                because these lines overwrite the id dynamically
                */
                event.target.id = "step" + this.completedSteps;
                return;
            }

            //check if address form step
            if (
                (this.completedSteps === MEDICARE_FORM_LENGTH - ONE_FORM_STEP &&
                    this.medicare === "medicare") ||
                (this.completedSteps ===
                    HEALTHCARE_FORM_LENGTH - ONE_FORM_STEP &&
                    this.medicare === "")
            ) {
                //check for erros in address form
                if (this.errorAddAndCityForm()) {
                    this.completedSteps += ONE_FORM_STEP;
                    event.target.id = "step" + this.completedSteps;
                }

                //if city input empty
                if (!this.model.city) {
                    this.model.errorcity = true;
                }

                //if zip input empty
                if (!this.model.zip) {
                    this.model.errorzip = true;
                }
                return;
            }
            
            if (this.flow != 'medicare' && this.completedSteps === 5) {    
                var newUrlIS;
                let str = window.location.href;
                let afterQuestionMark = str.split("?")[1];
                //console.log(afterQuestionMark);

                //take out type param if there in case user              
                afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=ace_medicare",
                    ""
                );
                afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=healthcare",
                    ""
                );
                 afterQuestionMark = afterQuestionMark.replace(
                    "&agegroup=aca",
                    ""
                );
                
                //check if income is over 40k
                if (this.model.income <= 40000) {  
                  agegroup = 'aca';                 
                    newUrlIS =
                        window.location.origin +
                        "/healthcare?" +
                        afterQuestionMark +
                        "&agegroup=aca";
                } else  {
                    this.flow = "healthcare";                   
                    agegroup = 'healthcare';  
                    newUrlIS =
                        window.location.origin +
                        "/healthcare?" +
                        afterQuestionMark +
                        "&agegroup=healthcare";
                }

                //puts new url in search and navigates to page
                history.pushState({}, null, newUrlIS);
            }
             //localStorage.setItem('ageGroup',agegroup);
               this.model.agegroup = agegroup;
              
               this.model.type = this.flow ;
                this.getTags();
               
                //   console.log(localStorage.getItem('ageGroup'));

            //if here not done or needing to
            //skip a step / do anything special
            //so just go up one step
            this.completedSteps += ONE_FORM_STEP;
            event.target.id = "step" + this.completedSteps;
        },
        validateInfo: function(e) {
            //reset name errors
            this.model.errorlast = false;
            this.model.errorfirst = false;

            //validate first name
            if (
                this.model.first_name == "" ||
                this.model.first_name.length < 2
            ) {
                this.model.errorfirst = true;
            }

            //validate last name
            if (this.model.last_name == "" || this.model.last_name.length < 2) {
                this.model.errorlast = true;
            }

            //email
            if (this.model.email == "") {
                this.model.erroremail = true; //empty email
            } else {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.model.erroremail = false;

                if (!re.test(this.model.email)) {
                    this.model.erroremail = true; //incorrectly formatted email
                }
            }

            //empty phone
            if (this.model.phone1 != "" && this.model.phone1.length == 3) {
                this.model.errorphone1 = false;
            } else {
                this.model.errorphone1 = true;
            }

            if (this.model.phone2 != "" && this.model.phone2.length == 3) {
                this.model.errorphone2 = false;
            } else {
                this.model.errorphone2 = true;
            }

            if (this.model.phone3 != "" && this.model.phone3.length == 4) {
                this.model.errorphone3 = false;
            } else {
                this.model.errorphone3 = true;
            }

            return (
                this.model.errorphone3 === false &&
                this.model.errorphone2 === false &&
                this.model.errorphone1 === false &&
                this.model.erroremail === false &&
                this.model.errorfirst === false &&
                this.model.errorlast === false
            );
        },
        inputValidation(value) {
            // validate value
            // console.log(value);
            const validatedValue = value.replace(/[^0-9]\./g, "");

            console.log(validatedValue);
            return validatedValue;
        },
        checkAndMoveToNext1(e) {
            this.clearPhone1();

            if (e.keyCode == BACKSPACE) return;

            var p1 = document.getElementById("phone1").value;
            document.getElementById("phone1").value = this.inputValidation(p1);
            var phone1Length = document.getElementById("phone1").value.length;

            if (phone1Length >= 3) {
                e.preventDefault();
            }

            if (phone1Length == 3) {
                this.model.phone1 = p1;
                var p2 = document.getElementById("phone2");
                p2.focus();
            }
        },
        checkAndMoveToNext2(e) {
            this.clearPhone2();

            if (e.keyCode == BACKSPACE) return;

            var p2 = document.getElementById("phone2").value;
            document.getElementById("phone2").value = this.inputValidation(p2);
            var phone2Length = document.getElementById("phone2").value.length;

            if (phone2Length >= 3) {
                e.preventDefault();
            }

            if (phone2Length == 3) {
                this.model.phone2 = p2;
                var p3 = document.getElementById("phone3");
                p3.focus();
            }
        },
        checkAndMoveToNext3(e) {
            this.clearPhone3();

            if (e.keyCode == BACKSPACE) return;

            var p3 = document.getElementById("phone3").value;

            document.getElementById("phone3").value = this.inputValidation(p3);
            var phone3Length = document.getElementById("phone3").value.length;

            if (phone3Length >= 4) {
                var btn =
                    document.getElementById("step" + MEDICARE_FORM_LENGTH) ||
                    document.getElementById("step" + HEALTHCARE_FORM_LENGTH);
                btn.focus();
                e.preventDefault();
            }
        },
        validateIncomeOnCreate: function(e) {
            for (var i = 0; i < this.formValues.incomes.length; i++) {
                if (this.formValues.incomes[i] == income) {
                    return true;
                }
            }
            return false;
        },
        validateHouseholdOncreate: function(e) {
            // alert(this.formValues.households);
            for (var i = 0; i < this.formValues.households.length; i++) {
                if (this.formValues.households[i] == household) {
                    return true;
                }
            }
            return false;
        },
        validateGenderOncreate: function(e) {
            if (this.model.gender == "M" || this.model.gender == "F")
                return true;
            return false;
        },
        validateDobOncreate: function(e) {
            if (this.validateDobDayOnCreate()) {
                this.model.dob_day = "DD";
            }
            if (this.validateDobMonthOnCreate()) {
                this.model.dob_month = "MM";
            }
            if (this.validateDobYearOnCreate()) {
                if (this.flow == "medicare") {
                    this.model.dob_year = "1956";
                } else {
                    this.model.dob_year = "1980";
                }
            }
            if (this.model.dob_day != "DD" && this.model.dob_month != "MM") {
                return true;
            }
            return false;
        },
        validateDobDayOnCreate: function(e) {
            for (var i = 1; i < this.formValues.days.length; i++) {
                if (this.formValues.days[i] == dobday) {
                    return false;
                }
            }
            return true;
        },
        validateDobMonthOnCreate: function(e) {
            for (var i = 1; i < this.formValues.months.length; i++) {
                if (this.formValues.months[i] == dobmonth) {
                    return false;
                }
            }
            return true;
        },
        validateDobYearOnCreate: function(e) {
            for (var i = 0; i < this.formValues.years.length; i++) {
                if (this.formValues.years[i] == dobyear) {
                    return false;
                }
            }
            return true;
        },
        validateDate: function(e) {
            if (this.flow == "medicare") {
                return true;
            }
            if (this.model.dob_day != "DD") {
                this.model.errorday = false;
            }

            if (this.model.dob_month != "MM") {
                this.model.errormonth = false;
            }

            if (this.model.dob_day != "DD" && this.model.dob_month != "MM") {
                return true;
            }

            if (this.model.dob_day == "DD") {
                this.model.errorday = true;
            }
            if (this.model.dob_month == "MM") {
                this.model.errormonth = true;
            }

            return false;
        },
        backStep: function(e) {
            this.transitionName = "bounce1";

            //go down a step
            this.completedSteps -= ONE_FORM_STEP;
            changeButtonId(this.completedSteps);

            //go down another if the user skipped
            //going up
            if (
                this.stepAmount == 2 &&
                this.completedSteps == 3 &&
                this.flow == "medicare"
            ) {
                this.completedSteps -= ONE_FORM_STEP;
                changeButtonId(this.completedSteps);
            }

            //?
            if (this.model.city == "") {
                this.model.city = this.model.citygoogle;
            }
        },
        showMedicareValue() {
          
        },
        cleanDob: function () {                       
            var dobMonth = this.model.dob_month
             if(dobMonth ==="MM"){
               dobMonth = "01"
             }
             var dobDay = this.model.dob_day
              if(dobDay ==="DD"){
               dobDay = "01"
             }             
             var dobYear =  this.model.dob_year             
             //if the DOB Year is YYYY this will never be a valid DOB, so return undefined so it doesn't cause date formatting issues with downstream tools.
             if (dobYear ==='YYYY'){
                 return undefined
                 }
             var dob = dobYear+"-"+dobMonth+"-"+dobDay                 
             return dob            
        },
        onComplete: function(e) {
            if (
                this.validateInfo(e) &&
                this.checkValidPhone(
                    this.model.phone1 + "-" + this.model.phone2
                )
            ) {
                if (window.dataLayer) {                     
                    dataLayer.push({'event': 'dl - lead_converted',
                        'data':
                          {
                        'firstname':  this.model.first_name,
                        'lastname':  this.model.last_name,
                        'email':  this.model.email,
                        'zip_code':  this.model.zip,
                        'birthdate':  this.cleanDob(),
                        'gender': this.model.gender,
                        'address': this.model.address,
                        'city': this.model.city,
                        'state': this.model.state,
                        'phone_number': "+1" + this.model.phone1 + this.model.phone2 + this.model.phone3,
                        'household_size': this.model.household,
                        'household_income': this.model.income,
                        }
                        });
                }
                let tcpa =  document.getElementById("tcpa") ?  document.getElementById("tcpa") : '' ; 
                this.model.tcpa = tcpa.textContent;               
                this.getGeoInfo();                
                this.postData(this.model);

                this.completedSteps = 8;
                this.renderThankYou();
            }

            if (addDataDestinations()) {
                console.log("data destinations was a go");
            } else {
                console.log("data destinations was a no go");
            }

            localStorage.setItem(
                "phonenumber",
                this.model.phone1 +
                    " " +
                    this.model.phone2 +
                    " " +
                    this.model.phone3
            );
            if (checkConnectMe()) {
                connectMe();
            }
        },
        getAge: function(birthDate, ageAtDate) {
            var daysInMonth = 30.436875; // Days in a month on average.
            var dob = new Date(birthDate);
            var aad;
            if (!ageAtDate) aad = new Date();
            else aad = new Date(ageAtDate);
            var yearAad = aad.getFullYear();
            var yearDob = dob.getFullYear();
            var years = yearAad - yearDob; // Get age in years.
            dob.setFullYear(yearAad); // Set birthday for this year.
            var aadMillis = aad.getTime();
            var dobMillis = dob.getTime();
            if (aadMillis < dobMillis) {
                --years;
                dob.setFullYear(yearAad - 1); // Set to previous year's birthday
                dobMillis = dob.getTime();
            }
            var days = (aadMillis - dobMillis) / 86400000;
            var monthsDec = days / daysInMonth; // Months with remainder.
            var months = Math.floor(monthsDec); // Remove fraction from month.
            days = Math.floor(daysInMonth * (monthsDec - months));
            return { years: years, months: months, days: days };
        },
        setzipformation: function(info) {
            this.model.city = info.city;
            this.model.citygoogle = info.city;
            this.model.state = info.state;
        },
        next: function() {
            this.model.zip = this.getQueryVariable("zipcode");
        },
        setaddressformation: function(address) {
            this.model.address = address;
        },

        setlocationinformation: function(info) {
            this.model.city = info.city;
            this.model.state = info.state;
            this.model.address = info.address;
        },
        renderThankYou: function() {
            document.getElementById("progress-wrapper").className = "hide";

            if (this.medicare == "medicare") {
                document.getElementById("buttond-holder-1").className = "hide";
            } else {
                document.getElementById("buttond-holder").className = "hide";
            }

            document.getElementById("header-desk").innerHTML =
                "Click at least 2 to 3 companies below to find the best rate!";
            document.getElementById("header-mobile").innerHTML =
                "Click at least 2 to 3 companies below to find the best rate!";

            var zip = "";
            var aff = "";
            var rid = "";
            var s1 = this.model.source1;
            var s2 = this.model.source2;

            rid = this.model.requestid;

            if (this.model.zip == "undefined" || this.model.zip == "") {
                zip = "99999";
            } else {
                zip = this.model.zip;
                //console.log(zip);
            }

            aff = this.model.affiliate;

            var bday =
                this.model.dob_year +
                "-" +
                this.model.dob_month +
                "-" +
                this.model.dob_day;

            var age = this.getAge(bday);

            let showme = this.model.showme;
            let show = "QS";
            // let rand = Math.floor(Math.random() * Math.floor(1000));

            // rand = 900;

            // if (showme == "QS") {
            //     show = "QS";
            // } else if (showme == "MA") {
            //     show = "MA";
            // } else {
            //     if (rand <= 900) {
            //         show = "MA";
            //     } else {
            //         show = "QS";
            //     }
            // }

            let clicklist = "";

            var premium = [543, 544, "a", 240];
            var internal = [545, 542, 559, 179];

            switch (show) {
                case "MA":
                    console.log("MA shown");
                    if (age.years) {
                        //console.log("age = ", age.years);
                        if (age.years >= 65) {
                            //Show Med Supp if older than 64.5

                            clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";

                            if (premium.includes(aff)) {
                                clicklist = "MAfr0rhZFRXFwDgiHhBC3Y7kCQjfiw";
                            } else if (internal.includes(aff)) {
                                clicklist = "IcmVWSgdowOFpDmwgwi_emkMeZhKdQ";
                            } else {
                                clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";
                            }
                        } else {
                            //Default U65
                            clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";

                            if (premium.includes(aff)) {
                                clicklist = "x30E_wy_fbHiPsE7xLoRh1_3bL8VsQ";
                            } else if (internal.includes(aff)) {
                                clicklist = "OXVTE9_7z-K_NC_wOrP277vINTyyhA";
                            } else {
                                clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";
                            }
                        }
                    } else {
                        console.log("flow = ", this.flow);
                        if (this.flow == "medicare") {
                            //Show Med Supp if older than 64.5

                            clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";

                            if (premium.includes(aff)) {
                                clicklist = "MAfr0rhZFRXFwDgiHhBC3Y7kCQjfiw";
                            } else if (internal.includes(aff)) {
                                clicklist = "IcmVWSgdowOFpDmwgwi_emkMeZhKdQ";
                            } else {
                                clicklist = "_qUR-TO_S1-hZnsg-AVHBQZLiouQmA";
                            }
                        } else {
                            //Default U65
                            clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";

                            if (premium.includes(aff)) {
                                clicklist = "x30E_wy_fbHiPsE7xLoRh1_3bL8VsQ";
                            } else if (internal.includes(aff)) {
                                clicklist = "OXVTE9_7z-K_NC_wOrP277vINTyyhA";
                            } else {
                                clicklist = "zt_q-nPkJsBDljp--GoX8ADc5gIpyQ";
                            }
                        }
                    }
                    //script from thanks page - Media Alpha

                    localStorage.setItem("clicklist", clicklist);
                    localStorage.setItem("s1", s1);
                    localStorage.setItem("s2", rid);
                    localStorage.setItem("a", aff);
                    localStorage.setItem("zip", zip);
                    localStorage.setItem("gender", this.model.gender);
                    localStorage.setItem(
                        "birth_date",
                        this.model.dob_year +
                            "-" +
                            this.model.dob_month +
                            "-" +
                            this.model.dob_day
                    );
                    localStorage.setItem("income", this.model.income);

                    loadAdUnit();

                    break;
                case "QS":
                    this.ten = true;

                    console.log("QS shown");

                    if (age.years) {
                        //console.log("age = ", age.years);
                        if (age.years >= 65) {
                            if (premium.includes(aff)) {
                                clicklist = 665509;
                            } else if (internal.includes(aff)) {
                                clicklist = 665510;
                            } else {
                                clicklist = 663719;
                            }
                        } else {
                            if (premium.includes(aff)) {
                                clicklist = 665512;
                            } else if (internal.includes(aff)) {
                                clicklist = 665513;
                            } else {
                                clicklist = 663716;
                            }
                        }
                    } else {
                        //console.log("flow = ", this.flow);
                        if (this.flow == "medicare") {
                            if (premium.includes(aff)) {
                                clicklist = 665509;
                            } else if (internal.includes(aff)) {
                                clicklist = 665510;
                            } else {
                                clicklist = 663719;
                            }
                        } else {
                            if (premium.includes(aff)) {
                                clicklist = 665512;
                            } else if (internal.includes(aff)) {
                                clicklist = 665513;
                            } else {
                                clicklist = 663716;
                            }
                        }
                    }

                    var matchingConfiguration = {
                        src: clicklist,
                        trn_id: "transactionId",
                        leadid: "",
                        zipcode: zip,
                        var1: aff,
                        var2: s1,
                        var3: rid,
                        householdsize: household,
                        age: age,
                        gender: gender
                    };

                    localStorage.setItem(
                        "matchingConfigurationLocal",
                        JSON.stringify(matchingConfiguration)
                    );
                    loadQS();

                    console.log(clicklist);

                    break;
                default:
                    break;
            }
        },
        getGeoInfo: function() {
            var current = this;

            //production staging url
            let backendURL =
                "https://gorgeous-jakarta-bmrwwnrr8b1p.vapor-farm-c1.com/api/checkzip";

            //backend staging url
            //let backendURL =
            //    "https://resilient-night-enwldlt94wk1.vapor-farm-c1.com/api/checkzip";

            //FOR LOCAL TESTING ONLY
            //let backendURL = "http://127.0.0.1:8001/api/checkzip";

            $.ajax({
                type: "POST",
                url: backendURL,
                data: {
                    zip: zip
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    if (xhr.status == 404) {
                        console.log(thrownError);
                    }
                },
                success: function(res) {
                    //console.log(res);

                    //set state and short name
                    var state = res.data.stateShortName; //Needs to be shortname
                    var city = res.data.cityName;

                    var info = {
                        city,
                        state
                    };
                    current.setzipformation(info);
                }
            });
        },
        validateDOBTab: function() {
            //            var out = this.$refs.dobTabForm.validate();

            var out = this.validateDate();
            return out;
        },
        validateSecondTab: function() {
            var out = this.$refs.secondTabForm.validate();
            return out;
        },
        validateThirdTab: function() {
            this.last = true;
            this.back = "";
            return true;
        },
        validateFirstTab: function() {
            var out = this.$refs.thirdTabForm.validate();
            console.log(out);
            return out;
            //            return true;
        },
        postData: function(data) {

            // Start Everflow Pixel
                      let urlPath2 =
                      "https://www.hqudkd2.com/?nid=1561&transaction_id=" +
                      data.requestid ;                      

                      var everflowPix = document.getElementById("everflowPix");
                     
                      everflowPix.innerHTML =
                          '<iframe src="' +
                          urlPath2 + 
                          '" height="1" width="1" frameborder="0"></iframe>';
                     
                       console.log("eveflow appended");
                    //End Everflow Pixel                    
            const universal_leadid_el = document.getElementById("leadid_token");
            const universal_leadid_v = universal_leadid_el
                ? universal_leadid_el.value
                : "";

            const trusted_form_token_el = document.getElementById(
                "xxTrustedFormToken_0"
            );
            const trusted_form_token_v = trusted_form_token_el
                ? trusted_form_token_el.value
                : "";

            const trusted_form_url_el = document.getElementById(
                "xxTrustedFormCertUrl_0"
            );
            const trusted_form_url_v = trusted_form_url_el
                ? trusted_form_url_el.value
                : "";

            let rand = this.getRandomInt(1000);
            let affiliate = data.affiliate;
            let zipcode = data.zip;
            let gender = data.gender;
            let req = data.requestid;
            let bday =
                data.dob_year + "-" + data.dob_month + "-" + data.dob_day;
            let income = data.income;
            let s5 = data.source5;

            let ecakeid = "33";

            let boberdoo_src_log = "";

           if (window.location.search.indexOf('biden=yes') > -1) {
               boberdoo_src_log = "HQ_HealthCovMark_U65BC";               
            } else {
                if (this.flow == "medicare") {
                    boberdoo_src_log = "HQ_HealthCovMark_Med";
                } else {
                    boberdoo_src_log = "HQ_HealthCovMark_U65";
                }
            }

            if (data.oid == 903) {
                ecakeid = "259";
            }

            //production staging url
            let backendURL =
                "https://gorgeous-jakarta-bmrwwnrr8b1p.vapor-farm-c1.com/api/lead";

            //backend staging url
            /*let backendURL =
                "https://resilient-night-enwldlt94wk1.vapor-farm-c1.com/api/lead";*/

            //FOR LOCAL TESTING ONLY
            // let backendURL = "http://127.0.0.1:8001/api/lead";
            // data.address = "54 test";
            // data.dob_day = "01";
            // data.dob_month = "01";

            axios
                .post(backendURL, {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone1 + " " + data.phone2 + " " + data.phone3,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    email: data.email,
                    year: data.dob_year,
                    month: data.dob_month,
                    day: data.dob_day,
                    zip: data.zip,
                    ip_address: data.ip_address,
                    ip_address_error: data.ip_address_error,
                    gender: data.gender,
                    tcpa: data.tcpa,
                    currentlyEnrolled: data.currentlyEnrolled,
                    tobacco: data.tobacco,
                    self_employed: data.self_employed,
                    feet: data.feet,
                    weight: data.weight,
                    pregnant: data.pregnant,
                    medical: data.medical,
                    universal_leadid: universal_leadid_v,
                    trusted_form_token: trusted_form_token_v,
                    trusted_form_url: trusted_form_url_v,
                    trusted_form_ping: document.getElementById('xxTrustedFormPingUrl_0')?.value,
                    source: data.source,
                    affiliate: data.affiliate,
                    creative: data.creative,
                    redirect_db: data.redirect_db + "/" + this.flow,
                    requestid: data.requestid,
                    oid: data.oid,
                    is_robly: data.is_robly,
                    offer: data.offer,
                    tfn: data.tfn,
                    arcamax_signup: data.arcamax_signup,
                    boberdoo_src: boberdoo_src_log,
                    income: data.income,
                    household: data.household,
                    source1: data.source1,
                    source2: data.source2,
                    source3: data.source3,
                    source4: data.source4,
                    source5: data.source5,
                    test_lead: "0",
                    ecake: ecakeid,
                    flow: this.flow,
                    site: "hcm",
                    biden: data.biden === "yes" ? true : false
                })
                .then(response => {
                    console.log(response.data);
                    let rand = this.getRandomInt(1000);
                    let affiliate = data.affiliate;
                    let zipcode = data.zip;
                    let gender = data.gender;
                    let req = data.requestid;
                    let bday =
                        data.dob_year +
                        "-" +
                        data.dob_month +
                        "-" +
                        data.dob_day;
                    let income = data.income;
                    let s5 = data.source5;
                    let oid = data.oid;

                    let rid = data.requestid;
                    let urlPath =
                        "https://rdtrker03.com/p.ashx?o=" +
                        oid +
                        "&e=33&fb=1&r=" +
                        rid +
                        "&t=TRANSACTION_ID";

                    var cakepix = document.getElementById("cakepix");
                      
                    cakepix.innerHTML =
                        '<iframe src="' +
                        urlPath +
                        '" height="1" width="1" frameborder="0"></iframe>';
                    console.log("cake appended");
                         
                    
                })
                .catch(error => {
                    console.log("error:", error);
                });
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        },
        prettyJSON: function(json) {
            /*if (json) {
                json = JSON.stringify(json, undefined, 4);
                json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
                return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                    var cls = 'number';
                    if (/^"/.test(match)) {
                        if (/:$/.test(match)) {
                            cls = 'key';
                        } else {
                            cls = 'string';
                        }
                    } else if (/true|false/.test(match)) {
                        cls = 'boolean';
                    } else if (/null/.test(match)) {
                        cls = 'null';
                    }
                    return '<span class="' + cls + '">' + match + '</span>';
                });
            }*/

            console.log(json);
        }
    }
});
function changeButtonId(value) {
    $(".nextbutton").attr("id", "step" + value);
}
function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
}
